import {
  Autocomplete,
  Button,
  ButtonGroup,
  Card,
  Checkbox,
  Chip,
  InputAdornment,
  MenuItem,
  OutlinedInput,
  SvgIcon,
  TextField,
  Tooltip,
} from "@mui/material";
import DownloadIcon from "@mui/icons-material/Download";
import TableChartOutlinedIcon from "@mui/icons-material/TableChartOutlined";
import StackedLineChartIcon from "@mui/icons-material/StackedLineChart";
import { TbTruckDelivery } from "react-icons/tb";
import { Box } from "@mui/system";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { HiSearch } from "react-icons/hi";
import { useSelector } from "react-redux";
import { QuickSearchToolbarProps } from "../../component/search/types";
import { RootState } from "../../store/store";
import TableChartIcon from "@mui/icons-material/TableChart";
import { MdEditDocument } from "react-icons/md";
import LegendToggleIcon from "@mui/icons-material/LegendToggle";
import { dateFormater } from "../schedule/AddScheduleOrder";
import { checkPermission } from "../../utils/checkPermission";
import { generateCodeUrl } from "../../utils/generateCodeUrl";
import { finalUrl } from "../../api/Api.utils";
import { useState } from "react";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { truncateText } from "../../utils/truncateText";
import theme from "../../Themes";
import { AdapterLuxon } from "@mui/x-date-pickers/AdapterLuxon";
import { DateTime } from "luxon";
import { reformatDates } from "../../utils/reformatDates";
import titleCase from "../../utils/titleCase";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export const SearchComponent = () => (
  <Card sx={{ p: 2 }}>
    <OutlinedInput
      defaultValue=""
      fullWidth
      placeholder="Search customer"
      startAdornment={
        <InputAdornment position="start">
          <SvgIcon color="action" fontSize="small">
            <HiSearch />
          </SvgIcon>
        </InputAdornment>
      }
      sx={{ maxWidth: 500 }}
    />
  </Card>
);

export const LargeScreenTableSearch = (props: any) => {
  const { isMobileView, userInfo, masterLocation } = useSelector(
    (state: RootState) => state.globalAppState
  );
  const { filterData } = useSelector(
    (state: RootState) => state.steelOnOrderState
  );
  const {
    steelOnOrderFiltersData,
    setSteelOnOrderFiltersData,
    setPermission,
    isPurchaseView,
    setIsPurchaseView,
  } = props;
  // test
  const [value, setValue] = useState<string[] | []>([]);

  const updateSteelData = (
    value: string | number | string[],
    name: any,
    event?: any
  ) => {
    if (name === "code") {
      // @ts-ignore
      if (value.find((str: any) => str === "All")) value = [];
    }
    if (
      name === "purchaseStartDate" ||
      name === "purchaseEndDate" ||
      name === "deliveryStartDate" ||
      name === "deliveryEndDate"
    ) {
      if (event) value = event.toISODate();
      else value = "";
    }

    if (name === "location") {
      setPermission(() => {
        const getPermission = checkPermission(
          value as string,
          userInfo,
          "STEEL_ON_ORDER"
        );
        return getPermission;
      });
    }

    setSteelOnOrderFiltersData({
      ...steelOnOrderFiltersData,
      [name]: value !== "All" ? value : "",
    });
  };

  const onDownload = async () => {
    let {
      purchaseOrderNumber,
      supplier,
      status,
      steelName,
      code,
      brand,
      purchaseStartDate,
      purchaseEndDate,
      location,
    } = steelOnOrderFiltersData;

    purchaseStartDate = reformatDates(purchaseStartDate);
    purchaseEndDate = reformatDates(purchaseEndDate);

    const codeUrl = generateCodeUrl(code);
    const paramsUrl = `${codeUrl}${steelName ? `name=${steelName}&` : ""}${
      status ? `status=${status}&` : ""
    }${supplier ? `supplier=${supplier}&` : ""}${
      purchaseOrderNumber ? `orderNumber=${purchaseOrderNumber}&` : ""
    }${brand ? `brand=${brand}&` : ""}${
      purchaseEndDate ? `purchaseEndDate=${purchaseEndDate}&` : ""
    }${purchaseStartDate ? `purchaseStartDate=${purchaseStartDate}&` : ""}${
      location !== "all" ? `locationId=${location}&` : ""
    }`;

    const downloadUrl = `${finalUrl}steelOrder/${
      isPurchaseView ? "po" : "shipment"
    }/csvReport?${paramsUrl}`;
    window.location.href = downloadUrl;
  };

  const renderTags = (value: any, getTagProps: any) => {
    if (value.length === 0) return null;

    const totalSelectedCount = value.length - 1;
    const firstOption = value[0];

    return (
      <>
        <Chip
          label={truncateText(firstOption, 7)}
          {...getTagProps({ index: 0 })}
          size="small"
        />
        {totalSelectedCount > 0 ? <span>{`+${totalSelectedCount}`}</span> : ""}
      </>
    );
  };

  const clearAllFilters = () => {
    setValue([]);
    setSteelOnOrderFiltersData({
      purchaseOrderNumber: "",
      supplier: "",
      status: "",
      steelName: "",
      code: [],
      brand: "",
      purchaseStartDate: "",
      purchaseEndDate: "",
      deliveryStartDate: "",
      deliveryEndDate: "",
      shipmentType: "",
      location: masterLocation.locationId,
    });
  };
  if (filterData?.allowedLocations?.length) {
    return (
      <Box display={"flex"} alignItems={"flex-start"}>
        <Box p={2} className={isMobileView ? "mobileViewCls" : "normalFilter"}>
          <Box className={"resp-select"}>
            <Autocomplete
              multiple
              id="checkboxes-tags-code"
              size="small"
              options={filterData?.code}
              disableCloseOnSelect
              getOptionLabel={(option: string) => option}
              value={value}
              limitTags={1}
              onChange={(event: any, newValue: string[]) => {
                setValue(newValue);
                updateSteelData(newValue, `code`);
              }}
              renderTags={renderTags}
              renderOption={(props, option, { selected }) => (
                <li {...props}>
                  <Checkbox
                    icon={icon}
                    checkedIcon={checkedIcon}
                    style={{ marginLeft: "-10px" }}
                    checked={selected}
                  />
                  {option}
                </li>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Code"
                  InputProps={{
                    ...params.InputProps,
                    style: {
                      width: "226px",
                      height: "35px",
                      fontSize: "14px",
                    },
                  }}
                  InputLabelProps={{
                    sx: {
                      fontSize: "14px",
                    },
                  }}
                />
              )}
            />
          </Box>
          <Box className={"resp-select"}>
            <TextField
              type="text"
              name="purchaseOrderNumber"
              label={`Purchase Order No.`}
              select
              variant="outlined"
              fullWidth
              value={steelOnOrderFiltersData?.purchaseOrderNumber}
              onChange={(e) => updateSteelData(e.target.value, e.target.name)}
              size="small"
              InputProps={{
                sx: {
                  width: "108px",
                  height: "35px",
                  fontSize: "14px",
                },
              }}
              InputLabelProps={{
                sx: {
                  fontSize: "14px",
                },
              }}
            >
              {filterData?.orderNumber?.length ? (
                <MenuItem value={"All"}>None</MenuItem>
              ) : (
                "no data"
              )}
              {filterData?.orderNumber?.map((option: any, i: number) => (
                <MenuItem key={i} value={option}>
                  {option}
                </MenuItem>
              ))}
            </TextField>
          </Box>
          <Box className={"resp-select"}>
            <TextField
              type="text"
              name="supplier"
              label={`Supplier`}
              select
              variant="outlined"
              fullWidth
              value={steelOnOrderFiltersData?.supplier}
              onChange={(e) => updateSteelData(e.target.value, e.target.name)}
              size="small"
              InputProps={{
                sx: {
                  width: "108px",
                  height: "35px",
                  fontSize: "14px",
                },
              }}
              InputLabelProps={{
                sx: {
                  fontSize: "14px",
                },
              }}
            >
              {filterData?.supplier?.length ? (
                <MenuItem value={"All"}>None</MenuItem>
              ) : (
                "no data"
              )}
              {filterData?.supplier?.map((option: any, i: number) => (
                <MenuItem key={i} value={option}>
                  {option}
                </MenuItem>
              ))}
            </TextField>
          </Box>
          <Box className={"resp-select"}>
            <TextField
              type="text"
              name="steelName"
              label={`Product Name`}
              select
              variant="outlined"
              fullWidth
              value={steelOnOrderFiltersData?.steelName}
              onChange={(e) => updateSteelData(e.target.value, e.target.name)}
              size="small"
              InputProps={{
                sx: {
                  width: "108px",
                  height: "35px",
                  fontSize: "14px",
                },
              }}
              InputLabelProps={{
                sx: {
                  fontSize: "14px",
                },
              }}
            >
              {filterData?.steelNames?.length ? (
                <MenuItem value={"All"}>None</MenuItem>
              ) : (
                "no data"
              )}
              {filterData?.steelNames?.map((option: any, i: number) => (
                <MenuItem key={i} value={option}>
                  {option}
                </MenuItem>
              ))}
            </TextField>
          </Box>
          <Box className={"resp-select"}>
            <TextField
              type="text"
              name="brand"
              label={`Brand`}
              select
              variant="outlined"
              fullWidth
              value={steelOnOrderFiltersData?.brand}
              onChange={(e) => updateSteelData(e.target.value, e.target.name)}
              size="small"
              InputProps={{
                sx: {
                  width: "108px",
                  height: "35px",
                  fontSize: "14px",
                },
              }}
              InputLabelProps={{
                sx: {
                  fontSize: "14px",
                },
              }}
            >
              {filterData?.brand?.length ? (
                <MenuItem value={"All"}>None</MenuItem>
              ) : (
                "no data"
              )}
              {filterData?.brand?.map((option: any, i: number) => (
                <MenuItem key={i} value={option}>
                  {option}
                </MenuItem>
              ))}
            </TextField>
          </Box>
          {isPurchaseView ? (
            <Box className={"resp-select"}>
              <TextField
                type="text"
                name="status"
                label={`Status`}
                select
                variant="outlined"
                fullWidth
                value={steelOnOrderFiltersData?.status}
                onChange={(e) => updateSteelData(e.target.value, e.target.name)}
                size="small"
                InputProps={{
                  sx: {
                    width: "108px",
                    height: "35px",
                    fontSize: "14px",
                  },
                }}
                InputLabelProps={{
                  sx: {
                    fontSize: "14px",
                  },
                }}
              >
                {filterData?.status?.length ? (
                  <MenuItem value={"All"}>None</MenuItem>
                ) : (
                  "no data"
                )}
                {filterData?.status?.map((option: any, i: number) => (
                  <MenuItem key={i} value={option}>
                    {titleCase(option)}
                  </MenuItem>
                ))}
              </TextField>
            </Box>
          ) : (
            <Box className={"resp-select"}>
              <TextField
                type="text"
                name="shipmentType"
                label={`Shipment Type`}
                select
                variant="outlined"
                fullWidth
                value={steelOnOrderFiltersData?.shipmentType}
                onChange={(e) => updateSteelData(e.target.value, e.target.name)}
                size="small"
                InputProps={{
                  sx: {
                    width: "108px",
                    height: "35px",
                    fontSize: "14px",
                  },
                }}
                InputLabelProps={{
                  sx: {
                    fontSize: "14px",
                  },
                }}
              >
                {filterData?.shipmentType?.length ? (
                  <MenuItem value={"All"}>None</MenuItem>
                ) : (
                  "no data"
                )}
                {filterData?.shipmentType?.map((option: any, i: number) => (
                  <MenuItem key={i} value={option}>
                    {titleCase(option)}
                  </MenuItem>
                ))}
              </TextField>
            </Box>
          )}
          <LocalizationProvider dateAdapter={AdapterLuxon}>
            <DatePicker
              label="Purchase Start Date"
              format="dd/MM/yyyy"
              onChange={(e) => updateSteelData("", "purchaseStartDate", e)}
              value={
                steelOnOrderFiltersData?.purchaseStartDate
                  ? DateTime.fromJSDate(
                      new Date(steelOnOrderFiltersData?.purchaseStartDate)
                    )
                  : null
              }
              slotProps={{
                textField: {
                  size: "small",
                  id: "purchaseStartDate",
                  name: "purchaseStartDate",
                  InputProps: {
                    sx: {
                      width: "108px",
                      height: "35px",
                      fontSize: "14px",
                    },
                  },
                  InputLabelProps: {
                    sx: {
                      fontSize: "14px",
                    },
                  },
                },
                actionBar: {
                  actions: ["clear"],
                },
              }}
            />
          </LocalizationProvider>
          <LocalizationProvider dateAdapter={AdapterLuxon}>
            <DatePicker
              label="Purchase End Date"
              format="dd/MM/yyyy"
              onChange={(e) => updateSteelData("", "purchaseEndDate", e)}
              value={
                steelOnOrderFiltersData?.purchaseEndDate
                  ? DateTime.fromJSDate(
                      new Date(steelOnOrderFiltersData?.purchaseEndDate)
                    )
                  : null
              }
              slotProps={{
                textField: {
                  size: "small",
                  id: "purchaseEndDate",
                  name: "purchaseEndDate",
                  InputProps: {
                    sx: {
                      width: "108px",
                      height: "35px",
                      fontSize: "14px",
                    },
                  },
                  InputLabelProps: {
                    sx: {
                      fontSize: "14px",
                    },
                  },
                },
                actionBar: {
                  actions: ["clear"],
                },
              }}
            />
          </LocalizationProvider>
          {!isPurchaseView && (
            <>
              <LocalizationProvider dateAdapter={AdapterLuxon}>
                <DatePicker
                  label="Delivery Start Date"
                  format="dd/MM/yyyy"
                  onChange={(e) => updateSteelData("", "deliveryStartDate", e)}
                  value={
                    steelOnOrderFiltersData?.deliveryStartDate
                      ? DateTime.fromJSDate(
                          new Date(steelOnOrderFiltersData?.deliveryStartDate)
                        )
                      : null
                  }
                  slotProps={{
                    textField: {
                      size: "small",
                      id: "deliveryStartDate",
                      name: "deliveryStartDate",
                      InputProps: {
                        sx: {
                          width: "108px",
                          height: "35px",
                          fontSize: "14px",
                        },
                      },
                      InputLabelProps: {
                        sx: {
                          fontSize: "14px",
                        },
                      },
                    },
                    actionBar: {
                      actions: ["clear"],
                    },
                  }}
                />
              </LocalizationProvider>
              <LocalizationProvider dateAdapter={AdapterLuxon}>
                <DatePicker
                  label="Delivery End Date"
                  format="dd/MM/yyyy"
                  onChange={(e) => updateSteelData("", "deliveryEndDate", e)}
                  value={
                    steelOnOrderFiltersData?.deliveryEndDate
                      ? DateTime.fromJSDate(
                          new Date(steelOnOrderFiltersData?.deliveryEndDate)
                        )
                      : null
                  }
                  slotProps={{
                    textField: {
                      size: "small",
                      id: "deliveryEndDate",
                      name: "deliveryEndDate",
                      InputProps: {
                        sx: {
                          width: "108px",
                          height: "35px",
                          fontSize: "14px",
                        },
                      },
                      InputLabelProps: {
                        sx: {
                          fontSize: "14px",
                        },
                      },
                    },
                    actionBar: {
                      actions: ["clear"],
                    },
                  }}
                />
              </LocalizationProvider>
            </>
          )}

          <Box className={"resp-select"}>
            <TextField
              type="text"
              name="location"
              label={`Location`}
              select
              variant="outlined"
              fullWidth
              value={steelOnOrderFiltersData?.location}
              onChange={(e) => updateSteelData(e.target.value, e.target.name)}
              size="small"
              InputProps={{
                sx: {
                  width: "108px",
                  height: "35px",
                  fontSize: "14px",
                },
              }}
              InputLabelProps={{
                sx: {
                  fontSize: "14px",
                },
              }}
            >
              {filterData?.allowedLocations?.length ? (
                <MenuItem value={"all"}>All</MenuItem>
              ) : (
                <MenuItem value={""}></MenuItem>
              )}
              {filterData?.allowedLocations?.length ? (
                filterData?.allowedLocations?.map((option: any, i: number) => (
                  <MenuItem key={i} value={option._id}>
                    {titleCase(option.fullName)}
                  </MenuItem>
                ))
              ) : (
                <MenuItem value={""}></MenuItem>
              )}
            </TextField>
          </Box>
          <Box className={"resp-select"}>
            <Button
              variant="outlined"
              onClick={clearAllFilters}
              sx={{
                width: "108px",
                height: "35px",
                fontSize: "14px",
                color: theme.palette.indicator.main,
              }}
            >
              Clear All
            </Button>
          </Box>
        </Box>
        <Box
          sx={{
            flex: "1",
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end",
            gap: "10px",
            marginBottom: "15px",
          }}
        >
          <ButtonGroup aria-label="outlined primary button group">
            <Tooltip title="Download Report">
              <Button
                startIcon={<DownloadIcon />}
                variant="contained"
                size="large"
                color="primary"
                sx={{
                  width: "54px",
                  height: "35px",
                  "& .MuiButton-startIcon": {
                    marginRight: "0",
                    marginLeft: "0",
                  },
                }}
                onClick={onDownload}
              />
            </Tooltip>
          </ButtonGroup>
          <ButtonGroup aria-label="outlined primary button group">
            <Tooltip title="Purchase Orders">
              <Button
                variant={isPurchaseView ? "contained" : "outlined"}
                onClick={() => setIsPurchaseView(true)}
                sx={{
                  width: "54px",
                  height: "35px",
                }}
              >
                <MdEditDocument size={"2em"} />
              </Button>
            </Tooltip>
            <Tooltip title="Shipments">
              <Button
                variant={!isPurchaseView ? "contained" : "outlined"}
                onClick={() => setIsPurchaseView(false)}
                sx={{
                  width: "54px",
                  height: "35px",
                }}
              >
                <TbTruckDelivery size={"2em"} />
              </Button>
            </Tooltip>
          </ButtonGroup>
        </Box>
      </Box>
    );
  }
  return <></>;
};


export interface SimpleDialogProps {
  open: boolean;
  selectedValue: string;
  onClose: (value: string) => void;
}

export const SteelOnOrderFilters = (props: any) => {
  return (
    <Box>
      <LargeScreenTableSearch {...props} />
    </Box>
  );
};
export const SteelOnOrderWithoutFilters = (props: QuickSearchToolbarProps) => {
  const { isPurchaseView, setIsPurchaseView } = props;
  return (
    <Box
      display={"flex"}
      justifyContent={"space-between"}
      alignItems={"center"}
      pr={2}
    >
      <Box></Box>
      <Box>
        <ButtonGroup aria-label="outlined primary button group">
          <Button
            variant={isPurchaseView ? "contained" : "outlined"}
            onClick={() => setIsPurchaseView(true)}
          >
            <TableChartIcon />
          </Button>
          <Button
            variant={!isPurchaseView ? "contained" : "outlined"}
            onClick={() => setIsPurchaseView(false)}
          >
            <LegendToggleIcon />
          </Button>
        </ButtonGroup>
      </Box>
    </Box>
  );
};
